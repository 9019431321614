import React, { useRef, useState, useEffect } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const twConfig = resolveConfig(tailwindConfig)

const ImageButton = styled.button`
    width: ${props => props.baseWidth}px;
    @media (min-width: ${twConfig.theme.screens.md}) {
        width: ${props => props.mdWidth}px;
    }
    @media (min-width: ${twConfig.theme.screens.lg}) {
        width: ${props => props.lgWidth}px;
    }
`

const OtcGallery = ({ images, popups, popupPrefix, baseWidth, mdWidth, lgWidth, textHeaders, textDescriptions, headerBackground }) => {

    const boxShadow = !!textHeaders && !!textDescriptions ? 'shadow-lg':'';

    baseWidth = !baseWidth? 100 : baseWidth;
    mdWidth = !mdWidth? 140 : mdWidth;
    lgWidth = !lgWidth? 160 : lgWidth;
    headerBackground = !headerBackground? 'bg-purpleheart' : headerBackground;

    popupPrefix = !popupPrefix ? '' : popupPrefix;

    let refPopup = useRef()
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        setVisible(selected != null? true : false)
    }, [selected])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    setSelected(null)
                }
            })
        }
    }, [])

    return (
        <div className="my-container" style={{ maxWidth:'872px' }}>

            <div className="relative z-50 flex flex-wrap items-center justify-center">
                {images && images.map((image) => (
                    <ImageButton 
                        key={image.id} 
                        baseWidth={baseWidth} mdWidth={mdWidth} lgWidth={lgWidth} 
                        onClick={ () => {
                            setSelected(image.name)
                        }}>
                        <GatsbyImage image={getImage(image)} alt={image.name}/>
                    </ImageButton>
                ))}
            </div>

            {visible?
            <div ref={ refPopup } 
                className="fixed inset-0 flex flex-col items-center justify-center bg-white bg-opacity-90 trustph-popup" 
                style={{zIndex:999999999}}
                onClick={(e) => {
                    e.preventDefault()
                    if (e.currentTarget === e.target) {
                        setSelected(null)
                    }
                }} 
                onKeyDown={(e) => {
                    if (e.key === "Escape") setSelected(null)
                }} 
                role="button" 
                tabIndex={0} 
                >
                <div className="flex flex-col items-center justify-center " style={{maxHeight:'80vh'}}>
                    {popups && popups.map(image => {
                        if (selected + popupPrefix === image.name) return (
                            <div key={image.id} style={{ maxWidth:'520px' }} className={`${boxShadow} relative`}>
                                <div className="relative">
                                    <button className={`absolute z-50 w-10 h-10 rounded-full shadow-md left-4 bg-pattensblue ${boxShadow? 'top-full mt-6':'top-4'}`} onClick={(e) => setSelected(null)}>
                                        <FontAwesomeIcon icon={ faTimes } className="w-full h-full text-gray-500"/>
                                    </button>
                                    {textHeaders ? <h4 className={`p-5 text-2xl tracking-wider text-center text-white rounded ${headerBackground}`}>{ textHeaders[selected] }</h4> : null}
                                </div>
                                
                                <div className={`flex items-center justify-center py-2 ${boxShadow?'bg-white':''}`}>
                                    <GatsbyImage image={getImage(image)} alt={image.name} 
                                        objectFit="contain" 
                                        objectPosition={'center'} 
                                        style={{maxHeight:'90vh'}} 
                                        imgStyle={{maxHeight:'70vh'}} 
                                    />
                                </div>

                                {textDescriptions ? <p className={`px-4 pb-4 text-sm tracking-wider leading-normal text-justify bg-white text-purpleheart`}>{ textDescriptions[selected] }</p> : null}
                            </div>
                        )
                        return null
                    })}
                </div>
                <p className="mt-5 text-sm tracking-wider text-center text-gray-400">Click 'x' button to dismiss</p>
            </div>
            :null}

        </div>
    )
}

export default OtcGallery
