import React, { useEffect, useRef } from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

import { InView } from 'react-intersection-observer'
import OtcGallery from '../../components/OtcGallery'
import styled from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config'
import lottie from 'lottie-web'
import condomData from '../../assets/CondomsIcons.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { SocialMediaLinks } from '../../utils'

import Cassette from '../../images/otc_pregnancy/cassette.png'
import MidStream from '../../images/otc_pregnancy/midstream.png'

const twConfig = resolveConfig(tailwindConfig)

const AnimatedCondom = styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0 auto;
    width: 62px;
    max-width: 160px;
    @media (min-width: ${twConfig.theme.screens.md}) {
        width: 160px;
    }
`

const StyledLogoGatsbyImage = styled(GatsbyImage)`
    width: 180px;
    @media (min-width: ${twConfig.theme.screens.md}) {
        width: 230px;
    }
`

const OtcSection = styled.section`
    padding-top: 2rem;
    padding-bottom: 6rem;
    background-color: ${twConfig.theme.colors.pattensblue};
`

const OverTheCounter = ({ pageContext, location }) => {

    const page = pageContext.page
    const refCondomLogo = useRef()
    const lottieCondomLogo = useRef()

    const data = useStaticQuery(graphql`
        fragment OtcImageFragment on File {
            id
            name
            childImageSharp {
                gatsbyImageData(
                    width: 160
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                )
            }
        }
        fragment OtcPopupFragment on File {
            id
            name
            childImageSharp {
                gatsbyImageData(
                    width: 600
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                )
            }
        }
        fragment OtcLogoSmallFragment on File {
            id
            name
            childImageSharp {
                gatsbyImageData(
                    width: 180
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                )
            }
        }
        fragment OtcLogoMediumFragment on File {
            id
            name
            childImageSharp {
                gatsbyImageData(
                    width: 230
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                )
            }
        }

        query {

            premiereSmall: file(relativePath: {eq: "Premiere_black.png"}) {
                ...OtcLogoSmallFragment
            }
            premiereMedium: file(relativePath: {eq: "Premiere_black.png"}) {
                ...OtcLogoMediumFragment
            }
            trustSmall: file(relativePath: {eq: "trust_logo_blue.png"}) {
                ...OtcLogoSmallFragment
            }
            trustMedium: file(relativePath: {eq: "trust_logo_blue.png"}) {
                ...OtcLogoMediumFragment
            }
            ezSmall: file(relativePath: {eq: "ez_logo_blue.png"}) {
                ...OtcLogoSmallFragment
            }
            ezMedium: file(relativePath: {eq: "ez_logo_blue.png"}) {
                ...OtcLogoMediumFragment
            }  
            
            premiereImages: allFile(filter: {relativeDirectory: {eq: "otc_premiere"}}, sort: {fields:[name], order: ASC}) {
                nodes {
                    ...OtcImageFragment
                }
            }
            premierePopups: allFile(filter: {relativeDirectory: {eq: "otc_premiere_popup"}}) {
                nodes {
                    ...OtcPopupFragment
                }
            }
            trustImages: allFile(filter: {relativeDirectory: {eq: "otc_trust"}}, sort: {fields:[name], order: ASC}) {
                nodes {
                    ...OtcImageFragment
                }
            }
            trustPopups: allFile(filter: {relativeDirectory: {eq: "otc_trust_popup"}}) {
                nodes {
                    ...OtcPopupFragment
                }
            }
            ovuImages: allFile(filter: {relativeDirectory: {eq: "otc_ovulation"}}, sort: {fields:[name], order: ASC}) {
                nodes {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 600
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
            ovuPopups: allFile(filter: {relativeDirectory: {eq: "otc_ovulation_popup"}}) {
                nodes {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 1200
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
            pregImages: allFile(filter: {relativeDirectory: {eq: "otc_pregnancy"}}, sort: {fields:[name], order: ASC}) {
                nodes {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 400
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
            pregPopups: allFile(filter: {relativeDirectory: {eq: "otc_pregnancy_popup"}}) {
                nodes {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 1200
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
            ezImages: allFile(filter: {relativeDirectory: {eq: "otc_ez"}}, sort: {fields:[name], order: ASC}) {
                nodes {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 160
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
            ezPopups: allFile(filter: {relativeDirectory: {eq: "otc_ez_popup"}}) {
                nodes {
                    id
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 854
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }

            favicon: file(relativePath: {eq: "logo-w431.png"}) {
                id
                childImageSharp {
                    gatsbyImageData(
                        width: 431
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                    original {
                        src
                    }
                }
            }
        }
    `)

    const premiereLogo = withArtDirection(getImage(data.premiereMedium), [
        {
            media: `(max-width: ${twConfig.theme.screens.md})`,
            image: getImage(data.premiereSmall)
        }
    ])    
    const trustLogo = withArtDirection(getImage(data.trustMedium), [
        {
            media: `(max-width: ${twConfig.theme.screens.md})`,
            image: getImage(data.trustSmall)
        }
    ])
    const ezLogo = withArtDirection(getImage(data.ezMedium), [
        {
            media: `(max-width: ${twConfig.theme.screens.md})`,
            image: getImage(data.ezSmall)
        }
    ])
 
    useEffect(() => {
        lottieCondomLogo.current = lottie.loadAnimation({
            container: refCondomLogo.current,
            animationData: condomData,
            loop: false,
            autoplay: false
        })
        lottieCondomLogo.current.play()
    }, [])

    return (
        <Layout>
            <Seo title={page.title} meta={[
                { property:'og:url', content:location.href }
            ].concat(SocialMediaLinks(data.favicon?.childImageSharp.original.src))}/>

            <div className="pt-10 md:pt-20 lg:pt-32 bg-pattensblue">
                <h2 className="m-auto text-3xl tracking-wider text-center text-purpleheart lg:text-5xl ">Condoms</h2>

                <InView as="div" onChange={(inView) => {
                    if (inView) {
                        lottieCondomLogo.current?.goToAndPlay(0)
                    }}}
                    style={{ minHeight:'94px' }}
                    >
                    <AnimatedCondom ref={ refCondomLogo }/>
                </InView>
            </div>

            <OtcSection>
                <div className="flex pb-10">
                    <StyledLogoGatsbyImage className="m-auto logo" image={ premiereLogo } alt="PREMIERE Condoms logo"/>
                </div>
                <OtcGallery images={ data.premiereImages.nodes } popups={ data.premierePopups.nodes } popupPrefix="_popup" headerBackground={'bg-red-600'}
                    textHeaders={{
                        "air": "PREMIERE Air",
                        "cruisecontrol": "PREMIERE Cruise Control",
                        "dotted": "PREMIERE Dotted",
                        "glow": "PREMIERE Glow",
                        "ultrathin": "PREMIERE Ultra Thin",
                        "nicetight": "PREMIERE Nice & Tight",
                        "watermelon": "PREMIERE Cool Watermelon",
                        "superstud": "PREMIERE Super Stud",
                        "z_earthquake": "PREMIERE Earthquake",
                    }}
                />
            </OtcSection>

            <OtcSection>
                <div className="flex pb-10">
                    <StyledLogoGatsbyImage className="m-auto logo" image={ trustLogo } alt="TRUST Condoms Logo"/>
                </div>
                <OtcGallery images={ data.trustImages.nodes } popups={ data.trustPopups.nodes } popupPrefix="_popup" mdWidth="160" lgWidth="180" headerBackground={'bg-blue-800'}
                    textHeaders={{
                        "bubblegum": "TRUST Bubblegum",
                        "importedchocolate": "TRUST Imported Chocolate",
                        "natural": "TRUST Natural",
                        "orange": "TRUST Orange",
                        "powderfresh": "TRUST Ultra Thin Powder Fresh Scent",
                        "showerfresh": "TRUST Ultra Thin Shower Fresh Scent",
                        "strawberry": "TRUST Strawberry",
                    }}
                />
            </OtcSection>

            {/* <h2 className='text-center text-6xl uppercase bg-pattensblue text-purpleheart'>Lubricants</h2> */}
            <h2 className="m-auto text-3xl tracking-wider text-center text-purpleheart lg:text-5xl bg-pattensblue ">Lubricants</h2>

            <OtcSection>
                <div className="flex pb-10">
                    <StyledLogoGatsbyImage className="m-auto logo" image={ ezLogo } alt="EZ Lubricants"/>
                </div>
                <OtcGallery style={{zIndex:99999999999}} images={ data.ezImages.nodes } popups={ data.ezPopups.nodes } popupPrefix="_popup" baseWidth="120"  
                    headerBackground={'bg-blue-400'}
                    textHeaders={
                        {
                            "a_ez10g": "EZ Lubricating Jelly 10g",
                            "b_ez5g": "EZ Cooling Jelly 5g",
                            "c_ez75g": "EZ Lubricating Jelly 75g",
                            "d_ez_bubblegum": "EZ Lubricating Jelly - Bubblegum",
                            "e_ez_strawberry": "EZ Lubricating Jelly - Strawberry",
                        }
                    } 
                    textDescriptions={{
                        "a_ez10g": "EZ Lubricating Jelly is a water-based, condom-friendly personal lubricant that provides the feeling of natural lubrication. No matter what you're up to, EZ Lubricating Jelly can surely amp up sensual pleasure!",
                        "b_ez5g": "EZ Cooling Jelly is a water-based, condom-friendly personal lubricant that provides the feeling of natural lubrication, with a cool twist. Its icy mint touch will awaken your senses for a more pleasurable and refreshing experience!",
                        "c_ez75g": "EZ Lubricating Jelly is a water-based, condom-friendly personal lubricant that provides the feeling of natural lubrication. No matter what you're up to, EZ Lubricating Jelly can surely amp up sensual pleasure!",
                        "d_ez_bubblegum": "New juicy taste, to make sliding into pleasure more fun!",
                        "e_ez_strawberry": "New juicy taste, to make sliding into pleasure more fun!",
                    }}/>
            </OtcSection>

            <div className="relative z-50 tracking-wider text-center text-blue-500 bg-pattensblue pb-11">    
                <OutboundLink href="https://doitright.ph/four-things-to-know-about-lube" target="_blank" rel="noreferrer">
                    Know more about lube here
                    <FontAwesomeIcon icon={ faChevronRight } className="ml-2"/>
                </OutboundLink>
            </div>




            <h2 className="m-auto text-3xl tracking-wider text-center text-purpleheart lg:text-5xl bg-pattensblue ">Ovulation Test Kit</h2>

            <OtcSection>
                <div className="flex pb-10">
                    {/* <StyledLogoGatsbyImage className="m-auto logo" image={ trustLogo } alt="TRUST Bloom"/> */}
                </div>
                <OtcGallery images={ data.ovuImages.nodes } popups={ data.ovuPopups.nodes } popupPrefix="_popup" baseWidth={260} mdWidth="400" lgWidth="400" headerBackground={'bg-blue-800'}
                    textHeaders={{
                        "bloom": "TRUST Bloom",
                    }}
                />
            </OtcSection>

         

            <h2 className="m-auto text-3xl tracking-wider text-center text-purpleheart lg:text-5xl bg-pattensblue ">Pregnancy Test Kits</h2>

            <OtcSection>
                <div className="flex pb-10">
                    {/* <StyledLogoGatsbyImage className="m-auto logo" image={ trustLogo } alt="TRUST Bloom"/> */}
                </div>
                <OtcGallery images={ data.pregImages.nodes } popups={ data.pregPopups.nodes } popupPrefix="_popup" baseWidth={200} mdWidth="400" lgWidth="380" headerBackground={'bg-blue-800'}
                    textHeaders={{
                        "cassette": "TRUST Clear Check Cassette Type",
                        "midstream": "TRUST Clear Check Mid-Stream Wand Type",
                    }}
                />
            </OtcSection>

            

        </Layout>
    )
}

export default OverTheCounter





